import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiGet from "../../../Hooks/useGet";
import { API_URL } from "../../../path";
import { Modal, Switch, message } from "antd";
import Header from "../../../Components/Header";
import Table from "../../../Components/Tabledata";
import useApiPost from "../../../Hooks/usePost";
import Loader from "../../../Components/Loader";

const Collegelist = () => {
  const Navigate = useNavigate();
  const CollegeList = useApiGet(`${API_URL}college/college_list/`);
  const Add_College = useApiPost(`${API_URL}college/add_college/`);
  const Edit_College = useApiPost(`${API_URL}college/edit_college/`);
  const Get_College = useApiPost(`${API_URL}college/college_det/`);
  const [Collegedetails, setCollegedetails] = useState({
    clg_id: "",
    college_name: "",
    contact_person: "",
    mobile_number: "",
    mail_id: "",
    address: "",
    pincode: "",
    is_valid: true,
  });
  const [Modaledit, setModaledit] = useState(false);
  const Inputfields = [
    {
      id: 1,
      label: "College name",
      placeholder: "Enter college name",
      name: "college_name",
      id: "college_name",
      value: Collegedetails.college_name,
      required: true,
      type: "text",
    },
    {
      id: 2,
      label: "Contact person",
      placeholder: "Enter contact person",
      name: "contact_person",
      id: "contact_person",
      value: Collegedetails.contact_person,
      required: false,
      type: "text",
    },
    {
      id: 3,
      label: "Mobile number",
      placeholder: "Enter mobile number",
      name: "mobile_number",
      id: "mobile_number",
      value: Collegedetails.mobile_number,
      required: false,
      type: "number",
    },
    {
      id: 4,
      label: "Mail id",
      placeholder: "Enter mail id",
      name: "mail_id",
      id: "mail_id",
      value: Collegedetails.mail_id,
      required: false,
      type: "text",
    },
    {
      id: 5,
      label: "Address",
      placeholder: "Enter address",
      name: "address",
      id: "address",
      value: Collegedetails.address,
      required: false,
      type: "text",
    },
    {
      id: 6,
      label: "Pincode",
      placeholder: "Enter pincode",
      name: "pincode",
      id: "pincode",
      value: Collegedetails.pincode,
      required: false,
      type: "number",
    },
  ];
  const ClosemodalHandler = () => {
    setCollegedetails((prev) => ({
      ...prev,
      clg_id: "",
      college_name: "",
      contact_person: "",
      mobile_number: "",
      mail_id: "",
      address: "",
      pincode: "",
      is_valid: true,
    }));
    setModaledit(false);
  };
  const AddcollegeHandler = () => {
    setCollegedetails((prev) => ({
      ...prev,
      clg_id: "",
      college_name: "",
      contact_person: "",
      mobile_number: "",
      mail_id: "",
      address: "",
      pincode: "",
      is_valid: true,
    }));
    setModaledit(true);
  };
  const EditHandler = (id) => {
    if (id)
      Get_College.postData({
        college_id: id,
      });
  };
  const InputHandler = (e) => {
    if (e.target.name === "college_name") {
      e.target.value = e.target.value?.replace(/[^a-zA-Z0-9&() \-'., ]+/g, "");
    }
    if (e.target.name === "contact_person") {
      e.target.value = e.target.value?.replace(/[^a-zA-Z ]+/g, "");
    }
    if (e.target.name === "mobile_number") {
      e.target.value = e.target.value?.slice(0, 10);
    }
    if (e.target.name === "mail_id") {
      e.target.value = e.target.value?.replace(/[^a-zA-Z0-9.@]+/g, "");
    }
    if (e.target.name === "address") {
      e.target.value = e.target.value?.replace(/[^a-zA-Z0-9.,-/& ]+/g, "");
    }
    if (e.target.name === "pincode") {
      e.target.value = e.target.value?.slice(0, 6);
    }
    setCollegedetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const FormSubmit = (e) => {
    e.preventDefault();
    let data = new FormData(e.target);
    let college_name = data.get("college_name");
    let postdata = {
      clg_id: Collegedetails.clg_id,
      college_name: data.get("college_name"),
      contact_person: data.get("contact_person"),
      mobile_number: data.get("mobile_number"),
      mail_id: data.get("mail_id"),
      address: data.get("address"),
      pincode: data.get("pincode"),
      is_valid: Collegedetails.is_valid,
    };
    if (college_name?.trim().length === 0) {
      message.error({
        type: "warning",
        content: `College name can't be empty`,
      });
      return;
    } else {
      if (postdata?.clg_id) {
        Edit_College.postData(postdata);
      } else {
        Add_College.postData(postdata);
      }
    }
  };

  useEffect(() => {
    if (CollegeList?.data === null) {
      CollegeList.getdata();
    }
  }, []);
  // ADD COLLEGE
  useEffect(() => {
    if (Add_College.data !== null) {
      if (Add_College?.data?.status === 200) {
        message.success({
          type: "Success",
          content: `College created successfully`,
        });
        CollegeList.getdata();
        ClosemodalHandler();
      }
    }
  }, [Add_College.data]);
  // EDIT COLLEGE
  useEffect(() => {
    if (Edit_College.data !== null) {
      if (Edit_College?.data?.status === 200) {
        message.success({
          type: "Success",
          content: `College updated successfully`,
        });
        CollegeList.getdata();
        ClosemodalHandler();
      }
    }
  }, [Edit_College.data]);

  // GET EDIT
  useEffect(() => {
    if (Get_College.data !== null) {
      if (Get_College?.data?.status === 200) {
        setCollegedetails((prev) => ({
          ...prev,
          clg_id: Get_College?.data?.body?.college_id,
          college_name: Get_College?.data?.body?.college_name,
          contact_person: Get_College?.data?.body?.contact_person,
          mobile_number: Get_College?.data?.body?.mobile_number,
          mail_id: Get_College?.data?.body?.mail_id,
          address: Get_College?.data?.body?.address,
          pincode: Get_College?.data?.body?.pincode,
          is_valid: Get_College?.data?.body?.is_valid,
        }));
        setModaledit(true);
      }
    }
  }, [Get_College.data]);

  return (
    <div className={`h-full flex items-start py-5 justify-center`}>
      <div className={`w-[95%] mx-auto`}>
        <Header title={"College list"} button={AddcollegeHandler} />

        <Table
          heading={["S.no", "College name", "Total user", "Active", "Action"]}
          loading={CollegeList?.loading}
        >
          <tbody>
            {CollegeList?.data?.status === 200 &&
              CollegeList?.data?.body?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`grid grid-cols-5 text-center py-2 px-2 border-b-[1px] border-Gray `}
                  >
                    <td className={`text-left`}>{index + 1}</td>
                    <td>{item?.college_name}</td>
                    <td>{item?.student_count}</td>
                    <td>{item?.is_valid ? "Yes" : "No"}</td>
                    <td>
                      <div>
                        <button
                          className={`text-Orange text-xl mx-2`}
                          onClick={() => {
                            Navigate(`/college/${item?.college_id}/2025`);
                          }}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </button>
                        <button
                          className={`text-Navy text-xl mx-2`}
                          onClick={() => {
                            EditHandler(item?.college_id);
                          }}
                        >
                          <i class="fa-solid fa-pen"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        <table className={`w-full hidden`}>
          <thead className="bg-Gray">
            <tr className={`grid grid-cols-4 p-2`}>
              <th className={`text-left`}>s.no</th>
              <th>College name</th>
              <th>Total user</th>
              <th>Action</th>
            </tr>
          </thead>
          {CollegeList.loading && (
            <tbody>
              <tr
                className={`grid grid-cols-3 text-center py-2 px-2 border-b-[1px] border-Gray`}
              >
                <td></td>
                <td className={`text-[64px]`}>
                  <i className="fa-solid fa-spinner fa-spin text-Orange"></i>
                </td>
                <td></td>
              </tr>
            </tbody>
          )}
          {!CollegeList?.loading && (
            <tbody>
              {CollegeList?.data?.status === 200 &&
                CollegeList?.data?.body?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`grid grid-cols-4 text-center py-2 px-2 border-b-[1px] border-Gray`}
                    >
                      <td className={`text-left`}>{index + 1}</td>
                      <td>{item?.college_name}</td>
                      <td>{item?.student_count}</td>
                      <td>
                        <div>
                          <button
                            className={`text-Orange text-xl mx-2`}
                            onClick={() => {
                              Navigate(`/college/${item?.college_id}/2025`);
                            }}
                          >
                            <i className="fa-solid fa-eye"></i>
                          </button>
                          <button
                            className={`text-Navy text-xl mx-2`}
                            onClick={() => {
                              setCollegedetails((prev) => ({
                                ...prev,
                                id: item?.college_id,
                                name: item?.college_name,
                              }));
                              setModaledit(true);
                            }}
                          >
                            <i class="fa-solid fa-pen"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>

      {/* ADD COLLEGE */}
      <Modal
        title="College detail"
        centered
        open={Modaledit}
        footer={false}
        onCancel={ClosemodalHandler}
      >
        <form onSubmit={FormSubmit}>
          {Inputfields?.map((item, index) => {
            return (
              <div key={item.id}>
                <label
                  htmlFor={item.id}
                  className="font-semibold my-1 block text-Navy"
                >
                  {item.label}
                </label>
                <input
                  type={item.type}
                  id={item?.id}
                  name={item?.name}
                  value={item?.value}
                  placeholder={item?.placeholder}
                  onInput={InputHandler}
                  className="border-2 w-full border-Navy py-1 px-2 rounded-md outline-none"
                  required={item?.required}
                />
              </div>
            );
          })}
          <div className="flex items-center my-2">
            <label
              htmlFor={"valid"}
              className="font-semibold my-1 block text-Navy text-md mr-2"
            >
              Active
            </label>
            <Switch
              id="valid"
              name="is_valid"
              checked={Collegedetails?.is_valid}
              className="bg-[#ccc]"
              onChange={(e) => {
                setCollegedetails((prev) => ({
                  ...prev,
                  is_valid: e,
                }));
              }}
            />
          </div>
          <button className="block mx-auto bg-Navy text-white my-3 font-semibold py-1 px-3 rounded-md outline-none">
            Submit
          </button>
        </form>
      </Modal>

      {(Add_College.loading || Edit_College.loading || Get_College.loading) && (
        <Loader />
      )}
    </div>
  );
};

export default Collegelist;
