import React, { useLayoutEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Login from "../Screens/Login";
import Dashboard from "../Screens/Dashboard";
import Sidebar from "../Components/Sidebar";
import Collegelist from "../Screens/Managecollege/Collegelist";
import Collegedata from "../Screens/Managecollege/Collegedata";
import Resource from "../Screens/Resource";
import Resorceedit from "../Screens/Resource/edit";
import Auth from "../Components/Auth";

const Navigation = () => {
  return (
    <div className={`w-full h-full`}>
      <Routes>
        <Route
          path="/"
          element={
            <div className={`w-full h-full flex`}>
              <div className={`w-[15%] h-full `}>
                <Auth />
                <Sidebar />
              </div>
              <div className={`w-[85%] h-full overflow-y-auto`}>
                <Outlet />
              </div>
            </div>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="college" element={<Collegelist />} />
          <Route path="college/:id/:flyear" element={<Collegedata />} />
          <Route path="resource" element={<Resource />} />
          <Route path="resource/:id" element={<Resorceedit />} />
        </Route>
        <Route
          path="/login"
          element={
            <>
              <Auth />
              <Login />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default Navigation;
