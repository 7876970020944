import React from "react";
import Chart from "react-apexcharts";

const Columnchart = ({ data = [], horizontal, title }) => {
  var series = [
    {
      name: data[0]?.action_desc,
      data: [data[0]?.total_count],
    },
    {
      name: data[1]?.action_desc,
      data: [data[1]?.total_count || 0],
    },
    {
      name: data[2]?.action_desc,
      data: [data[2]?.total_count || 0],
    },
  ];
  var options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: horizontal,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [title],
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  if (data?.length)
    return (
      <Chart
        options={options}
        series={data}
        type="bar"
        width={"100%"}
        height={400}
      />
    );
};

export default Columnchart;
