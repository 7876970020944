import React, { useEffect, useState } from "react";
import Layout from "./layout";
import useApiPost from "../../Hooks/usePost";
import { API_URL } from "../../path";
import useApiGet from "../../Hooks/useGet";
import FileSaver from "file-saver";
import useApiGetBlob from "../../Hooks/useGetblob";

const Dashboard = () => {
  const [Filtervalues, setFiltervalues] = useState({
    type: "monthly",
    yearly: new Date().getFullYear(),
    monthly: 1,
    week: "Week",
    daily: 1,
    multiday: "",
  });
  const OverallData = useApiPost(`${API_URL}dash_board/overall_data`);
  const Top_college = useApiPost(`${API_URL}dash_board/get_top_colleges`);
  const Preferred_Actions = useApiPost(
    `${API_URL}dash_board/preffered_action_point`
  );
  const JournalData_Year = useApiPost(
    `${API_URL}dash_board/journal_entry_analysis`
  );
  const JournalData = useApiPost(`${API_URL}dash_board/journal_entry_analysis`);
  const RegisterData = useApiPost(`${API_URL}dash_board/reg_analysis`);
  const RegisterData_year = useApiPost(`${API_URL}dash_board/reg_analysis`);
  const Preffered_Goal = useApiPost(`${API_URL}dash_board/preffered_goals`);
  const DownloadExcel = useApiGetBlob(`${API_URL}dash_board/download_report`);
  const DownloadExcelHandler = () => {
    DownloadExcel.getdata();
  };
  useEffect(() => {
    if (OverallData.data === null) {
      OverallData.postData();
    }
    if (Top_college.data === null) {
      Top_college.postData();
    }
    if (Preferred_Actions.data === null) {
      Preferred_Actions.postData();
    }
    if (JournalData_Year.data === null) {
      JournalData_Year.postData({
        type: "yearly",
      });
    }
    if (RegisterData_year.data === null) {
      RegisterData_year.postData({
        type: "yearly",
      });
    }
    if (Preffered_Goal.data === null) {
      Preffered_Goal.postData();
    }
  }, []);
  useEffect(() => {
    if (JournalData.data === null) {
      JournalData.postData({
        type: Filtervalues.type,
        year: Filtervalues?.yearly,
        month: Filtervalues?.monthly,
      });
    } else {
      JournalData.postData({
        type: Filtervalues.type,
        year: Filtervalues?.yearly,
        month: Filtervalues?.monthly,
      });
    }
    if (RegisterData.data === null) {
      RegisterData.postData({
        type: Filtervalues.type,
        year: Filtervalues?.yearly,
        month: Filtervalues?.monthly,
      });
    } else {
      RegisterData.postData({
        type: Filtervalues.type,
        year: Filtervalues?.yearly,
        month: Filtervalues?.monthly,
      });
    }
  }, [Filtervalues]);
  useEffect(() => {
    if (DownloadExcel?.data !== null) {
      FileSaver.saveAs(
        DownloadExcel?.data?.body,
        DownloadExcel?.data?.filename
      );
    }
  }, [DownloadExcel?.data]);

  return (
    <div className={`w-full h-full`}>
      <Layout
        OverallData={OverallData}
        Top_college={Top_college}
        Preferred_Actions={Preferred_Actions}
        JournalData={JournalData}
        Filtervalues={Filtervalues}
        setFiltervalues={setFiltervalues}
        JournalData_Year={JournalData_Year}
        RegisterData={RegisterData}
        RegisterData_year={RegisterData_year}
        Preffered_Goal={Preffered_Goal}
        DownloadExcelHandler={DownloadExcelHandler}
      />
    </div>
  );
};

export default Dashboard;
