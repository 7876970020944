import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const Piechart2 = ({ data }) => {
  const [Chartdata, setChartdata] = useState({
    labels: [],
    colors: [],
    series: [],
  });
  useEffect(() => {
    if (data?.length) {
      let duplicateState = {
        labels: [],
        colors: [],
        series: [],
      };

      data?.forEach((item, index) => {
        duplicateState.labels.push(item?.goal_title);
        duplicateState.colors.push(item?.icon_color);
        duplicateState.series.push(item?.user_count);
      });

      setChartdata(() => duplicateState);
    }
  }, [data]);

  var options = {
    labels: Chartdata?.labels || [],
    colors: Chartdata?.colors || [],
    tooltip: {
      y: {
        formatter: function (value, opts) {
          let percent = opts.globals.seriesPercent[opts.seriesIndex][0];
          //   return `${percent?.toString()?.slice(0, 4)}%`;
          // return `${percent?.toString()?.split(".")[0]}.${
          //   percent?.toString()?.split(".")[1]
          //     ? Math.ceil(percent?.toString()?.split(".")[1]?.slice(0, 1))
          //     : 0
          // }%`;
          return data[opts?.seriesIndex]?.percent;
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, index) {
        // return `${val?.toString()?.split(".")[0]}.${
        //   val?.toString()?.split(".")[1]
        //     ? Math.ceil(val?.toString()?.split(".")[1]?.slice(0, 1))
        //     : 0
        // }%`;
        return data[index?.seriesIndex]?.percent;
      },
    },
    legend: {
      formatter: function (seriesName, opts) {
        return `${seriesName} - ${opts.w.config?.series[opts?.seriesIndex]}`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  if (data?.length)
    return (
      <div>
        <Chart
          options={options}
          series={Chartdata?.series || []}
          type="pie"
          width={800}
          height={800}
        />
      </div>
    );
};

export default Piechart2;
