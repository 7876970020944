import React, { useState } from "react";
import { Logo1, Logo2 } from "../../Assets";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, message } from "antd";

const Sidebar = () => {
  const [Modalbox, setModalbox] = useState(false);
  const Navigate = useNavigate();
  return (
    <div
      className={`w-full h-full bg-[#c7ecff] flex flex-col justify-between shadow-lg shadow-Navy`}
    >
      <div>
        <div className={`flex flex-col items-center`}>
          <img src={Logo1} alt="Csim Logo" className={`w-[80px] my-4`} />
          <img src={Logo2} alt="Csim Logo" className={`w-[100px]`} />
        </div>

        <div className={`flex flex-col`}>
          <NavLink
            to={`/`}
            className={({ isActive, isPending }) =>
              isActive
                ? `bg-Navy text-white py-2 px-2 font-semibold border-b-[1px]`
                : `bg-  text-Navy py-2 px-2 font-semibold `
            }
          >
            <i className="fa-solid fa-chart-pie"></i> Dashboard
          </NavLink>
          <NavLink
            to={`/college`}
            className={({ isActive, isPending }) =>
              isActive
                ? `bg-Navy text-white py-2 px-2 font-semibold border-b-[1px]`
                : `bg-  text-Navy py-2 px-2 font-semibold border-b-[1px]`
            }
          >
            <i className="fa-solid fa-building-columns"></i> Manage college
          </NavLink>
          {/* <NavLink
          to={`/resource`}
          className={({ isActive, isPending }) =>
            isActive
              ? `bg-white text-[#158dca] py-2 px-2 font-semibold`
              : `bg-  text-white py-2 px-2 font-semibold `
          }
        >
          <i className="fa-solid fa-photo-film"></i> Add resources
        </NavLink> */}
        </div>
      </div>

      <div className="my-2">
        <button
          className="py-2 text-Navy text-center font-semibold w-full"
          onClick={() => setModalbox(true)}
        >
          <i className="fa-solid fa-right-from-bracket"></i> Logout
        </button>
      </div>

      <Modal
        title="Are you sure want to logout?"
        open={Modalbox}
        centered
        okType="danger"
        onOk={() => {
          localStorage.clear("SDG_TOKEN");
          Navigate("/login", { replace: true });
          message.success({
            type: "success",
            content: `Logout successfully`,
          });
        }}
        onCancel={() => {
          setModalbox(false);
        }}
      ></Modal>
    </div>
  );
};

export default Sidebar;
