
import { message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function useApiGetBlob(endpoint, initialData = null) {
    const Navigate = useNavigate()
    const [data, setData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getdata = () => {
        setLoading(true);
        setError(null);
        const urlencoded = new URLSearchParams();

        fetch(endpoint, {
            method: 'GET',
            headers: {
                'Authorization': `bearer ${localStorage.getItem("SDG_TOKEN")}`,
            },
        })
            // .then((r) => {
            //     console.log(r.headers.get('Content-Disposition'));
            // })
            .then((r) => r.blob().then((data) => ({
                status: r.status, body: data, filename: r.headers.get('Content-Disposition')?.split('=')[1]
            })))
            .then((result) => {
                setData(result);
                if (result.status !== 200) {
                    message.error({
                        type: "warning",
                        content: `Something went wrong`,
                    });
                }
                if (result.status === 403) {
                    Navigate('/login')
                }
            })
            .catch((err) => {
                message.error({
                    type: "warning",
                    content: `Something went wrong`,
                });
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });


    };

    return { data, loading, error, getdata, setData, setLoading, setError };
}

export default useApiGetBlob;
