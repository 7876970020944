import React, { useEffect } from "react";
import Layout from "./layout";
import useApiPost from "../../Hooks/usePost";
import { API_URL } from "../../path";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const Login = () => {
  const Navigate = useNavigate();
  const LoginApi = useApiPost(`${API_URL}login`);
  const LoginSubmit = (email, password) => {
    LoginApi.postData({
      email_id: email,
      password,
    });
  };
  useEffect(() => {
    if (LoginApi.data !== null) {
      if (LoginApi.data?.status === 200) {
        message.success({
          type: "success",
          content: `Login successfully`,
        });
        localStorage.setItem("SDG_TOKEN", LoginApi.data?.body?.token);
        Navigate("/", { replace: true });
      } else {
        message.error({
          type: "warning",
          content: `Invalid email and password`,
        });
      }
    }
  }, [LoginApi.data]);

  return (
    <div className={`w-full h-full`}>
      <Layout LoginSubmit={LoginSubmit} LoginApi={LoginApi} />
    </div>
  );
};

export default Login;
