import React from "react";

const Prize2 = () => {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4567_4592)">
        <path
          d="M16.756 35.8132L20.0993 30.5175C20.1934 30.3675 20.3728 30.2955 20.5433 30.3366L26.6359 31.7819C26.9623 31.8598 27.2402 31.5349 27.1138 31.2247L21.9415 18.5469L10.8516 23.0722L16.0238 35.75C16.1503 36.0617 16.5766 36.0985 16.756 35.8147V35.8132Z"
          fill="#F41943"
        />
        <path
          d="M18.6053 24.5547L14.99 33.2232L11.4541 24.5547H18.6053Z"
          fill="#BA0F38"
        />
        <path
          d="M18.2948 23.0708L17.6921 24.5469L15.4647 30.0058L15.372 30.2308L14.5708 32.195L13.1211 35.7485C12.9947 36.0602 12.5683 36.097 12.3889 35.8132L9.04564 30.5189C8.95154 30.3705 8.77365 30.2969 8.60163 30.3381L2.509 31.7833C2.18261 31.8613 1.90474 31.5363 2.03118 31.2247L7.20344 18.5469L18.2934 23.0722L18.2948 23.0708Z"
          fill="#F41943"
        />
        <path
          d="M23.4581 22.2636C22.9744 22.6944 19.5532 25.6451 14.5735 25.6451C13.637 25.6451 12.699 25.5481 11.7831 25.3526C9.52773 24.8733 7.4106 23.7956 5.69043 22.2636L7.20624 18.5469L8.94257 19.2555L9.09989 19.3202L14.5735 21.552L20.0472 19.3202L20.2045 19.2555L21.9409 18.5469L23.4567 22.2636H23.4581Z"
          fill="#BA0F38"
        />
        <path
          d="M14.5733 24.5352C21.3485 24.5352 26.8408 19.0428 26.8408 12.2676C26.8408 5.49239 21.3485 0 14.5733 0C7.79805 0 2.30566 5.49239 2.30566 12.2676C2.30566 19.0428 7.79805 24.5352 14.5733 24.5352Z"
          fill="#EBF2F2"
        />
        <path
          d="M14.5732 23.4471C20.7459 23.4471 25.7498 18.4431 25.7498 12.2704C25.7498 6.09772 20.7459 1.09375 14.5732 1.09375C8.40045 1.09375 3.39648 6.09772 3.39648 12.2704C3.39648 18.4431 8.40045 23.4471 14.5732 23.4471Z"
          fill="#C3DBDA"
        />
        <path
          d="M14.5731 22.7595C20.3682 22.7595 25.0661 18.0616 25.0661 12.2665C25.0661 6.47132 20.3682 1.77344 14.5731 1.77344C8.77796 1.77344 4.08008 6.47132 4.08008 12.2665C4.08008 18.0616 8.77796 22.7595 14.5731 22.7595Z"
          fill="#EBF2F2"
        />
        <path
          d="M19.8806 3.2172L5.14746 16.8771C4.46381 15.4848 4.08008 13.9205 4.08008 12.2679C4.08008 11.0418 4.29179 9.86557 4.67993 8.77172L12.0487 2.08072C12.8573 1.88076 13.7027 1.77344 14.5731 1.77344C16.5079 1.77344 18.3222 2.29978 19.8806 3.2172Z"
          fill="white"
        />
        <path
          d="M24.3326 8.41949L9.99348 21.7133C8.54972 21.0105 7.28973 19.9858 6.30762 18.7317L21.8009 4.67188C22.8962 5.71427 23.768 6.99043 24.3326 8.41949Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4567_4592"
          x="0"
          y="0"
          width="29.1445"
          height="40.0078"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4567_4592"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4567_4592"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Prize2;
