import React from "react";

const University = () => {
  return (
    <svg
      fill="#fff"
      height="50px"
      width="50px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <polygon points="0,158.694 0,219.615 110.641,219.615 204.562,158.694 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="307.438,158.694 401.359,219.615 512,219.615 512,158.694 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="240.779,26.873 240.779,135.204 256,125.331 271.221,135.204 271.221,111.033 369.875,74.578 		" />
        </g>
      </g>
      <g>
        <g>
          <path d="M367.034,364.262c-8.051,0-14.6,6.549-14.6,14.6v106.264h29.2V378.861C381.633,370.811,375.084,364.262,367.034,364.262z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M144.966,364.262c-8.051,0-14.6,6.549-14.6,14.6v106.264h29.2V378.861C159.567,370.811,153.017,364.262,144.966,364.262z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M256,364.262c-8.05,0-14.599,6.549-14.599,14.599v106.265h29.199V378.861C270.599,370.811,264.05,364.262,256,364.262z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,243.015c-9.427,0-17.097,7.669-17.097,17.097s7.67,17.097,17.097,17.097c9.427,0,17.097-7.67,17.097-17.097
           S265.427,243.015,256,243.015z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,161.616l-125.633,81.49v93.155c4.582-1.575,9.49-2.443,14.599-2.443c24.837,0,45.043,20.206,45.043,45.043v106.264
           h20.949V378.86c0-24.836,20.206-45.042,45.042-45.042s45.042,20.206,45.042,45.042v106.265h20.949V378.861
           c0-24.837,20.206-45.043,45.042-45.043c5.109,0,10.018,0.868,14.6,2.443v-93.155L256,161.616z M256,307.651
           c-26.213,0-47.539-21.326-47.539-47.539s21.326-47.539,47.539-47.539c26.213,0,47.539,21.326,47.539,47.539
           C303.539,286.325,282.213,307.651,256,307.651z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M0,250.058v235.068h99.924V378.861V250.058H0z M65.183,440.968H34.74v-33.69h30.443V440.968z M65.183,384.436H34.74
           v-33.69h30.443V384.436z M65.183,327.905H34.74v-33.69h30.443V327.905z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M412.076,250.058v128.803v106.264H512V250.058H412.076z M477.259,440.968h-30.443v-33.69h30.443V440.968z
            M477.259,384.436h-30.443v-33.69h30.443V384.436z M477.259,327.905h-30.443v-33.69h30.443V327.905z"
          />
        </g>
      </g>
    </svg>
  );
};

export default University;
