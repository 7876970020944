import React from "react";
import Header from "../../Components/Header";
import Table from "../../Components/Tabledata";
import { useNavigate } from "react-router-dom";

const Layout = () => {
  const Navigate = useNavigate();
  return (
    <div className={`h-full flex items-start py-5 justify-center`}>
      <div className={`w-[95%] mx-auto`}>
        <Header title={"Goals list"} button={false} />
        <Table heading={["S.no", "Goal name", "Action"]} loading={false}>
          <tbody>
            {[...new Array(17)].map((item, index) => {
              return (
                <tr
                  key={index}
                  className={`grid grid-cols-3 text-center py-2 px-2 border-b-[1px] border-Gray`}
                >
                  <td className={`text-left`}>{index + 1}</td>
                  <td>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Eligendi, corrupti!
                  </td>
                  <td>
                    <button
                      className={`text-Orange text-xl mx-2`}
                      onClick={() => {
                        Navigate(`/resource/1`);
                      }}
                    >
                      <i className="fa-solid fa-eye"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Layout;
