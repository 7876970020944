import React from "react";
import Layout from "./layout";

const Resource = () => {
  return (
    <div className="h-full">
      <Layout />
    </div>
  );
};

export default Resource;
