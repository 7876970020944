import React from "react";
import { Area } from "@ant-design/plots";
const Areachart = ({ data, color, x, y }) => {
  const config = {
    data: data,
    // : [
    //   { year: "1991", value: 15468 },
    //   { year: "1992", value: 16100 },
    //   { year: "1993", value: 15900 },
    //   { year: "1994", value: 17409 },
    //   { year: "1995", value: 17000 },
    //   { year: "1996", value: 31056 },
    //   { year: "1997", value: 31982 },
    //   { year: "1998", value: 32040 },
    //   { year: "1999", value: 33233 },
    // ]
    xField: "x",
    yField: "y",
    style: {
      fill: `linear-gradient(-90deg, white 0%, ${color} 100%)`,
    },
    axis: {
      // y: { labelFormatter: "~s" },
      x: {
        title: x,
      },
      y: {
        title: y,
      },
    },
    line: {
      style: {
        stroke: color,
        strokeWidth: 2,
      },
    },
    tooltip: (
      d, // 每一个数据项
      index, // 索引
      data, // 完整数据
      column // 通道
    ) => ({
      field: "y",
      channel: "y",
      name: d.x,
      color: color,
      value: d.y,
      // value:index==0&& "`${column.y.value[index]} - ${column.y1.value[index]}`",
      // value: `${JSON.stringify(d)} ${JSON.stringify(column)}`,
    }),

    // tooltip: {
    //   items: [{ channel: "x" }, { channel: "y" }],
    // },
  };
  if (data) {
    return <Area {...config} />;
  }
};

export default Areachart;
