import React from "react";

const Table = ({ heading, loading, children }) => {
  return (
    <table className={`w-full`}>
      <thead className="bg-Gray sticky top-0">
        <tr className={`grid grid-cols-${heading?.length} p-2`}>
          {heading?.map((item, index) => {
            return (
              <th key={index} className={`${index === 0 ? "text-left" : ""}`}>
                {item}
              </th>
            );
          })}
        </tr>
      </thead>
      {loading && (
        <tbody>
          <tr
            className={`grid grid-cols-${
              (heading?.length + 1) % 2
                ? Math.ceil((heading.length + 1) / 2)
                  ? heading?.length + 1
                  : ""
                : (heading?.length + 1) / 2
                ? heading?.length
                : ""
            } text-center py-2 px-2 border-b-[1px] border-Gray`}
          >
            {heading?.map((item, index) => {
              return (
                <td key={index} className={`text-[64px]`}>
                  {(heading?.length + 1) % 2 ? (
                    Math.ceil((heading.length + 1) / 2) === index + 1 ? (
                      <i className="fa-solid fa-spinner fa-spin text-Orange"></i>
                    ) : (
                      ""
                    )
                  ) : (heading?.length + 1) / 2 === index + 1 ? (
                    <i className="fa-solid fa-spinner fa-spin text-Orange"></i>
                  ) : (
                    ""
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      )}
      {!loading && children}
    </table>
  );
};

export default Table;
